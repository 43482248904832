import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Button from '../components/button/button'
import Testimonial from '../components/testimonial/testimonial'
import Services from '../components/services/services'
import SEO from "../components/seo"
import { motion } from "framer-motion"

import image from '../images/projects.jpg'


const ProjectsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      alexpanels: file(relativePath: { eq: "AlexPanels.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      linearconcepts: file(relativePath: { eq: "LinearConcepts.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      metamorphosis: file(relativePath: { eq: "Metamorphosis.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kpl: file(relativePath: { eq: "KPL.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bellad: file(relativePath: { eq: "BelladandCompany.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const transition = {
    type: "spring",
    mass: 0.35,
    stiffness: 75,
    duration: 0.3
  };

  return (
  <Layout>
    <SEO title="Projects" />
    <section id={`cover`} className={`primary header-fix lg`} style={{
        backgroundImage: `url(${image})`,
        backgroundSize: `cover`,
        backgroundPosition: `center center`,
        backgroundRepeat: `no-repeat`,
        boxSizing: `border-box`
      }}>
      <div className={`content--md`}>
        <motion.h3 className={`center thin`} 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={transition}>Project Gallery</motion.h3>
        <motion.h1 className={`center`} initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 40 }}
          transition={transition}>A collection of selected work</motion.h1>
      </div>
    </section>
    <section className={``}>
      <div className={`content`}>
        <h2 className={`center margin-bottom`}>Browse through our projects</h2>
        <div className={`projects-wrapper`}>
          <div className={`col`}>
            <div className={`project`}>
              <div className={`thumbnail`}>
              <Img fluid={data.alexpanels.childImageSharp.fluid} />
              </div>
              <h4>Alex Panels</h4>
              <p>Web Development</p>
            </div>
            <div className={`project`}>
              <div className={`thumbnail`}>
              <Img fluid={data.metamorphosis.childImageSharp.fluid} />
              </div>
              <h4>Metamorphosis</h4>
              <p>Web Development</p>
            </div>
            <div className={`project`}>
              <div className={`thumbnail`}>
              <Img fluid={data.bellad.childImageSharp.fluid} />
              </div>
              <h4>Bellad and Company</h4>
              <p>Web Development</p>
            </div>
          </div>
          <div className={`col`}>
            <div className={`project`}>
              <div className={`thumbnail`}>
              <Img fluid={data.linearconcepts.childImageSharp.fluid} />
              </div>
              <h4>Linear Concepts</h4>
              <p>Web Development</p>
            </div>
            <div className={`project`}>
              <div className={`thumbnail`}>
              <Img fluid={data.kpl.childImageSharp.fluid} />
              </div>
              <h4>KPL Learning</h4>
              <p>Web Development</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={`dark`}>
      <div className={`content--sm`}>
        <h4 className={`center text`}>Planning a project?</h4>
        <h1 className={`center data-hover`}><Link to="/connect">Work with us</Link></h1>
      </div>
    </section>
  </Layout>
)}

export default ProjectsPage
